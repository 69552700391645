import React from 'react';
import styled from 'styled-components';
import make from '../img/make.png';
import modify from '../img/modify.png';
import mature from '../img/mature.png';
import isolate from '../img/isolate.png';
import greybg from '../img/grey-bg.jpg';
import { Boom, Wrapper, Navbar, MobileMenu, Footer } from './Elements';

const TechHead = styled.div`
    background-image: url(${greybg});
    background-size: cover;
    background-position: 50% 50%;
    height: 309px;
    padding-bottom: 4rem;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    text-align: center;
`;

const TechHeadWrapWrap = styled.div`
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

const TechHeadWrap = styled.div`
    float: none;
    margin: 0 auto;
    position: relative;
    width: 100%;
    min-height: 1px;
    display: block;
`;

const SubHeadline = styled.h2`
    font-size: 14px;
    color: #D83D20;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    letter-spacing: 2.75px;
    margin-bottom: 1rem;
    line-height: 1.1;
    margin-top: 0;
`;

const Headline = styled.h1`
    padding-left:20%;
    padding-right:20%;
    font-size: 33px;
    line-height: 1.4;
    font-family: 'Signika', sans-serif;
    color:#00535D;
    @media (max-width:652px) {
        font-size: 20px;
    }
`;

const SecondSection = styled.div`
    position:relative;
    width:100%;
`;

const BlueBar = styled.div`
    background-color:#F4F7F9;
    height:140px;
    width:100%;
    margin-top:-57px;
`;

const WhatWeDo = styled.div`
    padding-left:30px;
    padding-top:30px;
`;

const WWDGreen = styled.div`
    font-family: 'Lato', sans-serif;
    font-weight:900;
    letter-spacing: -1px;
    color:#00535D;
    font-size:1.6em;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
`;

const WWDOrange = styled.div`
    font-family: 'Lato', sans-serif;
    font-weight:900;
    color:#D85125;
    letter-spacing: -1px;
    font-size:2em;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
`;

const WhiteBar = styled.div`
    &:after {
        clear: both;
    }
`;

const Column = styled.div`
    text-align:center;
    float: left;
    width: 33.33%;
    padding-top:120px;
    padding-bottom:80px;
    @media (max-width: 800px) {
        clear: both;
        float:none;
        width:100%;
        padding-top:20px;
        padding-bottom:20px;
    }
`;

const ColumnImg = styled.img`
    width:40%
    height:auto;
`;

const ColumnHeader = styled.div`
    margin-top:30px;
    color:#D85125;
    font-family: 'Lato', sans-serif;
    font-weight:900;
    font-size:1.3em;
`;

const ColumnBlurb = styled.div`
    font-size:1.2em;
    padding-left:15%;
    padding-right:15%;
    color:#666666;
`;

const IsolateWrap = styled.div`
    position:absolute;
    top:50%;
    right:15%;
    text-align:center;
    width:33%;
    @media (max-width: 800px) {
        top:72%;
        right:20px;
    }
`;

const IsolateImg = styled.img`
    width:50%
    height:auto;
    @media (max-width: 800px) {
        transform: rotate(100deg);
    }
`;

const IsolateHeader = styled.div`
    color:#D85125;
    font-family: 'Lato', sans-serif;
    font-weight:900;
    font-size:1.3em;
    margin-right:32px;
    @media (max-width: 800px) {
        margin-right:-20px;
    }
`;

const IsolateBlurb = styled.div`
    font-size:1.2em;
    padding-left:25%;
    padding-right:25%;
    color:#666666;
    margin-right:32px;
    @media (max-width: 800px) {
        margin-right:-20px;
    }
`;

class Tech extends React.Component {
    state = {
        mobile_display:"none",
        wrapper_margin:"0px",
        resources_ddd:"none",
        tech_ddd:"none",
    }
    mobileMenu = () => {
        var data = this;
        if (data.state.mobile_display === "none") {
            data.setState({
                mobile_display:"block"
            }, () => setTimeout(data.setState({wrapper_margin:"-320px"}), 1000));
        } else {
            data.setState({
                wrapper_margin:"0px"
            }, () => setTimeout(data.setState({mobile_display:"none"}), 1000));
        }
    }
    resourcesDD = () => {
        var data = this;
        if (data.state.resources_ddd === "none") {
            if (data.state.tech_ddd === "block") {
                data.setState({tech_ddd:"none"});
            }
            data.setState({resources_ddd:"block"});
        } else {
            data.setState({resources_ddd:"none"});
        }
    }
    techDD = () => {
        var data = this;
        if (data.state.tech_ddd === "none") {
            if (data.state.resources_ddd === "block") {
                data.setState({resources_ddd:"none"});
            }
            data.setState({tech_ddd:"block"});
        } else {
            data.setState({tech_ddd:"none"});
        }
    }
    render() {
        if (this.state) {
            return (
                <Boom>
                    <MobileMenu display={this.state.mobile_display} />
                    <Wrapper margin={this.state.wrapper_margin}>
                        <Navbar {...this.props} mobileMenu={this.mobileMenu} techDD={this.techDD} tech_display={this.state.tech_ddd} resourcesDD={this.resourcesDD} display={this.state.resources_ddd} />
                        <TechHead>
                            <TechHeadWrapWrap>
                                <TechHeadWrap>
                                    <SubHeadline>HNF4a development</SubHeadline>
                                    <Headline>Our integrated liver cell manufacturing process produces billions of top quality human hepatocytes.</Headline>
                                </TechHeadWrap>
                            </TechHeadWrapWrap>
                        </TechHead>                       
                        <SecondSection>
                            <BlueBar>
                                <WhatWeDo>
                                    <WWDGreen>ReLIVER</WWDGreen>
                                    <WWDOrange>WHAT WE DO</WWDOrange>
                                </WhatWeDo>
                            </BlueBar>
                            <WhiteBar>
                                <Column>
                                    <ColumnImg src={make} />
                                    <ColumnHeader>MAKE</ColumnHeader> 
                                    <ColumnBlurb>primary human hepatocytes from stem cells</ColumnBlurb>
                                </Column>
                                <Column>
                                    <ColumnImg src={modify} />
                                    <ColumnHeader>MODIFY</ColumnHeader>
                                    <ColumnBlurb>them using CRISPR/Cas9 gene-editing tools</ColumnBlurb>
                                </Column>
                                <Column>
                                    <ColumnImg src={mature} />
                                    <ColumnHeader>MATURE &amp; EXPAND</ColumnHeader>
                                    <ColumnBlurb>them in <i>in vivo</i> incubators</ColumnBlurb>
                                </Column>
                            </WhiteBar>
                            <IsolateWrap>
                                <IsolateImg src={isolate} />
                                <IsolateHeader>ISOLATE</IsolateHeader>
                                <IsolateBlurb>hepatocytes from cadaver livers</IsolateBlurb>
                            </IsolateWrap>
                        </SecondSection>
                        <Footer  />
                    </Wrapper>
                </Boom>
            );
        } else {
            return (
                <div>&nbsp;</div>
            );
        }
    }
}

export {Tech}
