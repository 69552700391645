import React from 'react';
import styled from 'styled-components';
import greybg from '../img/grey-bg.jpg';
import { Boom, Wrapper, Navbar, MobileMenu, Footer, Clearfix } from './Elements';

const TechHead = styled.div`
    background-image: url(${greybg});
    background-size: cover;
    background-position: 50% 50%;
    height: 209px;
    padding-bottom: 4rem;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    text-align: center;
`;

const TechHeadWrapWrap = styled.div`
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

const TechHeadWrap = styled.div`
    float: none;
    margin: 0 auto;
    position: relative;
    width: 100%;
    min-height: 1px;
    display: block;
`;

const SubHeadline = styled.h2`
    font-size: 14px;
    color: #D83D20;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    letter-spacing: 2.75px;
    margin-bottom: 1rem;
    line-height: 1.1;
    margin-top: 0;
`;

const Headline = styled.h1`
    padding-left:20%;
    padding-right:20%;
    font-size: 33px;
    line-height: 1.4;
    font-family: 'Signika', sans-serif;
    color:#00535D;
    @media (max-width:652px) {
        font-size: 20px;
    }
`;

const SecondSection = styled.div`
    position:relative;
    width:100%;
`;

const BlueBar = styled.div`
    background-color:#F4F7F9;
    height:140px;
    width:100%;
    margin-top:-57px;
`;

const WhatWeDo = styled.div`
    padding-left:30px;
    padding-top:30px;
`;

const WWDGreen = styled.div`
    font-family: 'Lato', sans-serif;
    font-weight:900;
    letter-spacing: -1px;
    color:#00535D;
    font-size:1.6em;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
`;

const WWDOrange = styled.div`
    font-family: 'Lato', sans-serif;
    font-weight:900;
    color:#D85125;
    letter-spacing: -1px;
    font-size:2em;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
`;

const WhiteBar = styled.div`
    max-width:1040px;
    margin: auto;
    &:after {
        clear: both;
    }
    box-sizing: border-box;
`;

const Pluripotent = styled.div`
    margin-top:60px;
    margin-bottom:60px;
    box-sizing: border-box;
    float:left;
    width:50%;
    padding-left:30px;
    padding-right:15px;
    @media (max-width:650px) {
        float:none;
        width:100%;
        padding-right:30px;
        margin-bottom:20px;
    }
`;

const Engineering = styled.div`
    margin-top:60px;
    margin-bottom:60px;
    box-sizing: border-box;
    float:right;
    width:50%;
    padding-right:30px;
    padding-left:15px;
    @media (max-width:650px) {
        margin-top:0px;
        float:none;
        width:100%;
        padding-left:30px;
    }
`;

const IPTitle = styled.div`
    font-weight:bold;
    color:#429DA9;
    font-size:1.2em;
    margin-bottom:30px;
`;

const IPBody = styled.div`
    color:#000000;
    font-size:1em;
    margin-bottom:30px;
`;

const IPLeft = styled.div`
    color:#00535D;
    width:35%;
    display:inline-block;
    vertical-align:top;
    margin-bottom:10px;
`;

const IPRight = styled.div`
    color:#00535D;
    width:65%;
    display:inline-block;
    vertical-align:top;
    margin-bottom:10px;
`;

class IP extends React.Component {
    state = {
        mobile_display:"none",
        wrapper_margin:"0px",
        resources_ddd:"none",
        tech_ddd:"none",
    }
    mobileMenu = () => {
        var data = this;
        if (data.state.mobile_display === "none") {
            data.setState({
                mobile_display:"block"
            }, () => setTimeout(data.setState({wrapper_margin:"-320px"}), 1000));
        } else {
            data.setState({
                wrapper_margin:"0px"
            }, () => setTimeout(data.setState({mobile_display:"none"}), 1000));
        }
    }
    resourcesDD = () => {
        var data = this;
        if (data.state.resources_ddd === "none") {
            if (data.state.tech_ddd === "block") {
                data.setState({tech_ddd:"none"});
            }
            data.setState({resources_ddd:"block"});
        } else {
            data.setState({resources_ddd:"none"});
        }
    }
    techDD = () => {
        var data = this;
        if (data.state.tech_ddd === "none") {
            if (data.state.resources_ddd === "block") {
                data.setState({resources_ddd:"none"});
            }
            data.setState({tech_ddd:"block"});
        } else {
            data.setState({tech_ddd:"none"});
        }
    }
    render() {
        if (this.state) {
            return (
                <Boom>
                    <MobileMenu display={this.state.mobile_display} />
                    <Wrapper margin={this.state.wrapper_margin}>
                        <Navbar {...this.props} mobileMenu={this.mobileMenu} techDD={this.techDD} tech_display={this.state.tech_ddd} resourcesDD={this.resourcesDD} display={this.state.resources_ddd} />
                        <TechHead>
                            <TechHeadWrapWrap>
                                <TechHeadWrap>
                                    <SubHeadline>HNF4a development</SubHeadline>
                                    <Headline>Intellectual Property</Headline>
                                </TechHeadWrap>
                            </TechHeadWrapWrap>
                        </TechHead>                       
                        <SecondSection>
                            <BlueBar>
                                <WhatWeDo>
                                    <WWDGreen>ReLIVER</WWDGreen>
                                    <WWDOrange>INTELLECTUAL PROPERTY</WWDOrange>
                                </WhatWeDo>
                            </BlueBar>
                            <WhiteBar>
                                <Pluripotent>
                                    <IPTitle>Human induced pluripotent stem cells for high efficiency genetic engineering.</IPTitle>
                                    <IPBody>This application describes the exact methods of generating human induced pluripotent stem cells containing custom-systems allowing high efficiency genetic engineering.</IPBody>
                                    <IPLeft><b>Publication #</b></IPLeft>
                                    <IPRight><b>Provisional Application #</b> 62/369,698,</IPRight>
                                    <IPLeft><b>Publication Date</b></IPLeft>
                                    <IPRight><b>Filing Date</b> August 1, 2016</IPRight>
                                </Pluripotent>
                                <Engineering>
                                    <IPTitle>Methods of engineering human induced pluripotent stem-derived livers in rats and uses thereof.</IPTitle>
                                    <IPBody>This application details 1) our method to differentiate human pluripotent stem cells into liver cells by using a sequential culture to obtain cells manifesting the phenotype of mature primary hepatocytes to a significant extent. 2) the creation of an animal bioreactor as a system for complete functional maturation of regeneration-responsive, iPS-derived hepatocytes with high-metabolic functionality, in sufficient numbers and with consistent function.</IPBody>
                                    <IPLeft><b>Publication #</b></IPLeft>
                                    <IPRight><b>Provisional Application #</b> 62/459,003,</IPRight>
                                    <IPLeft><b>Publication Date</b></IPLeft>
                                    <IPRight><b>Filing Date</b> February 14, 2017</IPRight> 
                                </Engineering>
                                <Clearfix />
                            </WhiteBar>
                        </SecondSection>
                        <Footer  />
                    </Wrapper>
                </Boom>
            );
        } else {
            return (
                <div>&nbsp;</div>
            );
        }
    }
}

export {IP}
