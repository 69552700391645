import React from 'react';
import styled, { keyframes } from 'styled-components';
//import reliver from '../img/reliver2.jpg';
import blackcell1 from '../img/home-black-cell-1.jpg';
import blackcell2 from '../img/home-black-cell-2.jpg';
import whitecell1 from '../img/home-white-cell-1.jpg';
import make from '../img/make.png';
import modify from '../img/modify.png';
import mature from '../img/mature.png';
import isolate from '../img/isolate.png';
import { Boom, Wrapper, Navbar, MobileMenu, Footer } from './Elements';

const slideSources = [
  blackcell1,
  whitecell1, 
  blackcell2
]

const Hero = styled.div`
    height: ${props => props.height};
    background-color: #CFCFD6;
    width: 100%;
    @media (max-width: 524px) {
        background-color: #F4F7F9;
    }
`;

//const ReLiverArt = styled.img`
//    display:inline-block;
//    -webkit-box-shadow: 4px 0px 9px 1px rgba(0,0,0,0.10);
//    -moz-box-shadow: 4px 0px 9px 1px rgba(0,0,0,0.10);
//    box-shadow: 4px 0px 9px 1px rgba(0,0,0,0.10);
//    @media (max-width: 524px) {
//        width:100%;
//        height:auto;
//    }
//`;

const ReLiverArt = styled.div`
    position: relative;
    display:inline-block;
    height:608px;
    width:100%;
    overflow:hidden;
    -webkit-box-shadow: 4px 0px 9px 1px rgba(0,0,0,0.10);
    -moz-box-shadow: 4px 0px 9px 1px rgba(0,0,0,0.10);
    box-shadow: 4px 0px 9px 1px rgba(0,0,0,0.10);
    @media (max-width: 524px) {
        width:100%;
        height:500px;
    }
`;

const kenburns = [
keyframes`
    0% {
        transform: scale3d(1.30, 1.30, 1.30);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        transform: scale3d(1.50, 1.50, 1.50) translate3d(-60px, -40px, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }
`,
keyframes`
    0% {
        transform: scale3d(1.30, 1.30, 1.30) translate3d(60px, 0px, 0px);
        opacity: 0;
    }   
    20% {
        opacity: 1;
    }   
    100% {
        transform: scale3d(1.50, 1.50, 1.50) translate3d(120px, -40px, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }   
`,
keyframes`
    0% {
        transform: scale3d(1.30, 1.30, 1.30);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        transform: scale3d(1.50, 1.50, 1.50) translate3d(-60px, -40px, 0px);
        animation-timing-function: ease-in;
        opacity: 1;
    }
`
]

const BurnsImg = styled.img`
    position:absolute;
    opacity:0;
    animation: ${props => { return kenburns[props.slideNumber-1]}} 10s;
    animation-iteration-count: infinite;
    animation-fill-mode: backwards;
    animation-play-state: running;
    z-index: ${props => {return props.zindex}};
`

const Holder = styled.div`
    position: absolute;
    margin-left:75px;
    z-index:1000, 
    display:inline-block;
    @media (max-width: 1140px) {
        margin-left:40px;
        width:40%;
    }
`;

const HomeArtMsg = styled.div`
    z-index: 1000;
    display: table-cell;
    height: 608px;
    vertical-align: middle;
    @media (max-width: 969px) {
        position:absolute;
        top:80px;
        left:60px;
        height:230px;
        width:400px;
        text-align:center;
        background: rgba(3, 13, 30, 0.5);
        padding:5px;
        padding-top:20px;
    }
    @media (max-width: 524px) {
        width: 80%;
        left: 8%;
    }
`;

const HugeOrange = styled.div`
    display:inline-block
    font-size:4em;
    font-weight:bold;
    color:#D83D20;
    text-shadow: 0px 0px 4px #000000;
    @media (max-width: 1140px) {
        font-size:2.5em;
    }
`;

const HomeBlurb = styled.div`
    margin-top:40px;
    max-width:500px;
    color:#FFFFFF;
    text-shadow: 0px 0px 4px #000000;
    line-height:40px;
    font-family: 'NTR', sans-serif;
    font-size:2.4em
    font-weight:bold;
    @media (max-width: 1140px) {
        font-size:1.5em;
        line-height:30px;
    }
    @media (max-width: 969px) {
        color:#ffffff;
    }
`;

const ArrowHolder = styled.div`
    position:relative;
    text-align:center;
    margin-top:-50px;
    z-index:99999;
`;

const SecondSection = styled.div`
    position:relative;
    width:100%;
`;

const BlueBar = styled.div`
    background-color:#F4F7F9;
    height:140px;
    width:100%;
    margin-top:-57px;
`;

const WhatWeDo = styled.div`
    padding-left:30px;
    padding-top:30px;
`;

const WWDGreen = styled.div`
    font-family: 'Lato', sans-serif;
    font-weight:900;
    letter-spacing: -1px;
    color:#00535D;
    font-size:1.6em;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
`;

const WWDOrange = styled.div`
    font-family: 'Lato', sans-serif;
    font-weight:900;
    color:#D83D20;
    letter-spacing: -1px;
    font-size:2em;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
`;

const WhiteBar = styled.div`
    &:after {
        clear: both;
    }
`;

const Column = styled.div`
    text-align:center;
    float: left;
    width: 33.33%;
    padding-top:120px;
    padding-bottom:80px;
    @media (max-width: 800px) {
        clear: both;
        float:none;
        width:100%;
        padding-top:20px;
        padding-bottom:20px;
    }
`;

const ColumnImg = styled.img`
    width:40%
    height:auto;
`;

const ColumnHeader = styled.div`
    margin-top:30px;
    color:#D83D20;
    font-family: 'Lato', sans-serif;
    font-weight:900;
    font-size:1.3em;
`;

const ColumnBlurb = styled.div`
    font-size:1.2em;
    padding-left:15%;
    padding-right:15%;
    color:#666666;
`;

const IsolateWrap = styled.div`
    position:absolute;
    top:50%;
    right:15%;
    text-align:center;
    width:33%;
    @media (max-width: 800px) {
        top:72%;
        right:20px;
    }
`;

const IsolateImg = styled.img`
    width:50%
    height:auto;
    @media (max-width: 800px) {
        transform: rotate(100deg);
    }
`;

const IsolateHeader = styled.div`
    color:#D83D20;
    font-family: 'Lato', sans-serif;
    font-weight:900;
    font-size:1.3em;
    margin-right:32px;
    @media (max-width: 800px) {
        margin-right:-20px;
    }
`;

const IsolateBlurb = styled.div`
    font-size:1.2em;
    padding-left:25%;
    padding-right:25%;
    color:#666666;
    margin-right:32px;
    @media (max-width: 800px) {
        margin-right:-20px;
    }
`;


class Arrow extends React.Component {
    render() {
        return (

          <ArrowHolder>
            <svg width="19px" height="104px" viewBox="0 0 19 104" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <defs />
              <g id="Full-Design" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" strokeLinecap="square" opacity="0.246037138" strokeLinejoin="round">
                <g id="Home-Page" transform="translate(-709.000000, -563.000000)" strokeWidth={2}>
                  <g id="Group-7" transform="translate(711.000000, 564.000000)">
                    <polyline id="Line-Copy" stroke="#A1A1A1" opacity="0.974184783" points="7.56643174 0 7.56643174 101 0 90.232517" />
                    <path d="M15.5664317,101 L8,90.232517" id="Line-Copy-4" stroke="#A4A4A4" transform="translate(11.783216, 95.616258) scale(-1, 1) translate(-11.783216, -95.616258) " />
                  </g>
                </g>
              </g>
            </svg>
          </ArrowHolder>
        );
    }
}

class Home extends React.Component {
    state = {
        image_height:"608px",
        mobile_display:"none",
        wrapper_margin:"0px",
        resources_ddd:"none",
        tech_ddd:"none",
        slides:['1','2','3'],
        slideStack:['1','2','3'],
        firstRun: true
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.changeSlide();
    }
    changeSlide() {
      let slides = this.state.slides
      slides.push(slides.shift())
      this.setState({
            slides: slides,
            firstRun: false
      }, () => {
        setTimeout(() => {this.changeSlide()}, 8000)
      })
    }
    updateDimensions = () => {
        var data = this;
        var height = document.getElementById("reliver").height;
        if (height !== data.state.image_height) {
            data.setState({image_height:height+"px"});
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    mobileMenu = () => {
        var data = this;
        if (data.state.mobile_display === "none") {
            data.setState({
                mobile_display:"block"
            }, () => setTimeout(data.setState({wrapper_margin:"-320px"}), 1000));
        } else {
            data.setState({
                wrapper_margin:"0px"
            }, () => setTimeout(data.setState({mobile_display:"none"}), 1000));
        }
    }
    resourcesDD = () => {
        var data = this;
        if (data.state.resources_ddd === "none") {
            if (data.state.tech_ddd === "block") {
                data.setState({tech_ddd:"none"});
            }
            data.setState({resources_ddd:"block"});
        } else {
            data.setState({resources_ddd:"none"});
        }
    }
    techDD = () => {
        var data = this;
        if (data.state.tech_ddd === "none") {
            if (data.state.resources_ddd === "block") {
                data.setState({resources_ddd:"none"});
            }
            data.setState({tech_ddd:"block"});
        } else {
            data.setState({tech_ddd:"none"});
        }  
    }
    render() {
        if (this.state.image_height) {
            return (
                <Boom>
                    <MobileMenu display={this.state.mobile_display} />
                    <Wrapper margin={this.state.wrapper_margin}>
                        <Navbar {...this.props} mobileMenu={this.mobileMenu} techDD={this.techDD} tech_display={this.state.tech_ddd} resourcesDD={this.resourcesDD} display={this.state.resources_ddd} />
                        <Hero height={this.state.image_height}>
                            <Holder style={{zIndex:1000}}>
                            <HomeArtMsg>
                                <HugeOrange>ReLIVER</HugeOrange>      
                                <HomeBlurb>
                                  Gene reprogramming to repair the failing cirrhotic liver ... <br />and reduce liver transplant waiting lists.
                                </HomeBlurb>
                            </HomeArtMsg>
                            </Holder>


                            <ReLiverArt id="reliver">
                              {this.state && this.state.slideStack && this.state.slideStack.map((slide, key) => {
                                let zindex = this.state.slides[0] === slide ? 3 :
                                this.state.slides[1] === slide ? 1 : 2
                                if(zindex === 2 || (zindex === 1 && this.state.firstRun !== true)) return <BurnsImg zindex={zindex} src={slideSources[slide-1]} slideNumber={slide} /> 
                                else return ''
                              })}
                            </ReLiverArt>
                        </Hero>
                        <Arrow />
                        <SecondSection>
                            <BlueBar>
                                <WhatWeDo>
                                    <WWDGreen>ReLIVER</WWDGreen>
                                    <WWDOrange>WHAT WE DO</WWDOrange>
                                </WhatWeDo>
                            </BlueBar>
                            <WhiteBar>
                                <Column>
                                    <ColumnImg src={make} />
                                    <ColumnHeader>MAKE</ColumnHeader> 
                                    <ColumnBlurb>primary human hepatocytes from stem cells</ColumnBlurb>
                                </Column>
                                <Column>
                                    <ColumnImg src={modify} />
                                    <ColumnHeader>MODIFY</ColumnHeader>
                                    <ColumnBlurb>them using CRISPR/Cas9 gene-editing tools</ColumnBlurb>
                                </Column>
                                <Column>
                                    <ColumnImg src={mature} />
                                    <ColumnHeader>MATURE &amp; EXPAND</ColumnHeader>
                                    <ColumnBlurb>them in <i>in vivo</i> incubators</ColumnBlurb>
                                </Column>
                            </WhiteBar>
                            <IsolateWrap>
                                <IsolateImg src={isolate} />
                                <IsolateHeader>ISOLATE</IsolateHeader>
                                <IsolateBlurb>hepatocytes from cadaver livers</IsolateBlurb>
                            </IsolateWrap>
                        </SecondSection>
                        <Footer  />
                    </Wrapper>
                </Boom>
            );
        } else {
            return (
                <div>&nbsp;</div>
            );
        }
    }
}

export {Home}
