import React from 'react';
import styled from 'styled-components';
import { Boom, Wrapper, Navbar, MobileMenu, Footer } from './Elements';
import greybg from '../img/grey-bg.jpg';
import ron from '../img/ronald_landes.jpg';
import christina from '../img/christina_galbraith.jpg';
import ira from '../img/ira-fox.jpg';
import alex from '../img/alex.jpg';

const CompanyHead = styled.div`
    background-image: url(${greybg});
    background-size: cover;
    background-position: 50% 50%;
    height: 309px;
    padding-bottom: 4rem;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    text-align: center;
`;

const CompanyHeadWrapWrap = styled.div`
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

const CompanyHeadWrap = styled.div`
    float: none;
    margin: 0 auto;
    position: relative;
    width: 100%;
    min-height: 1px;
    display: block;
`;

const SubHeadline = styled.h2`
    font-size: 14px;
    color: #D83D20;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    letter-spacing: 2.75px;
    margin-bottom: 1rem;
    line-height: 1.1;
    margin-top: 0;
`;

const Headline = styled.h1`
    padding-left:20%;
    padding-right:20%;
    font-size: 33px;
    line-height: 1.4;
    font-family: 'Signika', sans-serif;
    color:#00535D;
    @media (max-width:652px) {
        font-size: 20px;
    }
`;

const Personnel = styled.div`
    background-color: #F5F6F8;
    padding-bottom: 3rem;
    box-sizing: inherit;
`;

const TeamSelector = styled.div`
    background-color: #FFFFFF;
    box-shadow: 0 5px 24px rgba(0, 4, 11, 0.04);
    margin-bottom: 5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    box-sizing: inherit;
`;

const TeamContainer = styled.div`
    @media (min-width: 1200px) {
        width: 1140px;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    position: relative;
    margin-left: auto;
    margin-right: auto;
`;

const TeamMenu = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const SelectBlock = styled.div`
    flex-basis: auto;
    padding: 0 33px;
    text-align: center;
`;

const TeamItem = styled.a`
    //color: #D80000;
    color: ${props => props.color};
    position: relative;
    font-family: "lato", sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 12px;
    letter-spacing: 2.75px;
    text-decoration: none;
    touch-action: manipulation;
    background-color: transparent;
    cursor:pointer;
    &:after {
        display:${props => props.display};
        top: 200%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #fff;
        border-width: 12px;
        margin-left: -12px;
        box-sizing: inherit;
    }
`; 

const TeamNumber = styled.span`
    color: rgba(0, 4, 11, 0.22);
    font-family: "lato", sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 12px;
    letter-spacing: 2.75px;
`;

const Management = styled.div`
    display: ${props => props.display};
    @media (min-width: 1200px) {
        width: 1140px;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    position: relative;
    margin-left: auto;
    margin-right: auto;
`;

const Scientists = styled.div`
    display: ${props => props.display};
    @media (min-width: 1200px) {
        width: 1140px;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    position: relative;
    margin-left: auto;
    margin-right: auto;
`;

const TeamSection = styled.h3`
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 17px;
    color: #00040B;
    letter-spacing: 3.12px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.5rem;
    line-height: 1.1;
    margin-top: 0;
    &:after {
        content: '';
        display: block;
        height: 2px;
        width: 55px;
        margin: 1rem auto;
        background: rgba(172, 178, 182, 0.46);
    }
`;

const TeamMember = styled.div`
    padding-top: 2rem;
    padding-bottom: 4rem;
    @media (min-width: 1200px) {
        margin-right: -15px;
        margin-left: -15px;
    }
    display: flex;
    flex-wrap: wrap;
`;

const TeamPhotoHolder = styled.div`
    @media (min-width: 992px) {
        left: 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    padding-right: 25px;
    padding-left: 25px;
    position: relative;
    width: 100%;
    min-height: 1px;
`;

const TeamImage = styled.img`
    border-radius: 1px;
    margin-top: 0.3rem;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
`;

const BioHolder = styled.div`
    @media (min-width: 992px) {
        left: 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    padding-right: 25px;
    padding-left: 25px;
    position: relative;
    width: 100%;
    min-height: 1px;
`;

const BioName = styled.h4`
    font-family: "Signika", serif;
    font-size: 25px;
    color: #00040B;
    letter-spacing: 0;
    margin: 0 0 0.7rem;
    padding: 0;
    font-weight: 500;
    line-height: 1.1;
`;

const BioTitle = styled.h5`
    font-family: "Signika", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #D80000;
    letter-spacing: 0.3px;
    margin: 0 0 0.7rem;
    padding: 0;
    line-height: 1.1;
`;

const Bio = styled.div`
    margin-top: 2rem;
`;

const BioP = styled.p`
    margin-bottom: 20px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #242D2D;
    letter-spacing: 0.14px;
    line-height: 1.6;
    margin-top: 0;
`;

class Company extends React.Component {
    state = {
        mobile_display:"none",
        wrapper_margin:"0px",
        resources_ddd:"none",
        tech_ddd:"none",
        mgmt_display:"none",
        mgmt_arrow_display:"none",
        mgmt_color:"rgba(0, 4, 11, 0.44)",
        science_display:"block",
        science_arrow_display:"inherit",
        science_color:"#D80000",
    }
    mobileMenu = () => {
        var data = this;
        if (data.state.mobile_display === "none") {
            data.setState({
                mobile_display:"block"
            }, () => setTimeout(data.setState({wrapper_margin:"-320px"}), 1000));
        } else {
            data.setState({
                wrapper_margin:"0px"
            }, () => setTimeout(data.setState({mobile_display:"none"}), 1000));
        }
    }
    resourcesDD = () => {
        var data = this;
        if (data.state.resources_ddd === "none") {
            if (data.state.tech_ddd === "block") {
                data.setState({tech_ddd:"none"});
            }
            data.setState({resources_ddd:"block"});
        } else {
            data.setState({resources_ddd:"none"});
        }
    }
    techDD = () => {
        var data = this;
        if (data.state.tech_ddd === "none") {
            if (data.state.resources_ddd === "block") {
                data.setState({resources_ddd:"none"});
            }
            data.setState({tech_ddd:"block"});
        } else {
            data.setState({tech_ddd:"none"});
        }
    }
    teamGo = (team) => {
        var data = this;
        if (team === "mgmt") {
            if (data.state.mgmt_display === "none") {
                this.setState({
                    mgmt_display:"block",
                    mgmt_color:"#D80000",
                    mgmt_arrow_display:"inherit",
                    science_display:"none",
                    science_color:"rgba(0, 4, 11, 0.44)",
                    science_arrow_display:"none",
                });
            } else {
                this.setState({
                    mgmt_display:"none",
                    mgmt_color:"rgba(0, 4, 11, 0.44)",
                    mgmt_arrow_display:"none",
                    science_display:"block",
                    science_color:"#D80000",
                    science_arrow_display:"inherit",
                });
            }
        } else {
            if (data.state.science_display === "none") {
                this.setState({
                    science_display:"block",
                    science_color:"#D80000",
                    science_arrow_display:"inherit",
                    mgmt_display:"none",
                    mgmt_color:"rgba(0, 4, 11, 0.44)",
                    mgmt_arrow_display:"none",
                });
            } else {
                this.setState({
                    science_display:"none",
                    science_color:"rgba(0, 4, 11, 0.44)",
                    science_arrow_display:"none",
                    mgmt_display:"block",
                    mgmt_color:"#D80000",
                    mgmt_arrow_display:"inherit",
                });
            }
        }
    }
    render() {
        if (this.state.mobile_display) {
            return (
                <Boom>
                    <MobileMenu display={this.state.mobile_display} />
                    <Wrapper margin={this.state.wrapper_margin}>
                        <Navbar {...this.props} mobileMenu={this.mobileMenu} techDD={this.techDD} tech_display={this.state.tech_ddd} resourcesDD={this.resourcesDD} display={this.state.resources_ddd} />
                        <CompanyHead>
                            <CompanyHeadWrapWrap>
                                <CompanyHeadWrap>
                                    <SubHeadline>COMPANY - ABOUT US</SubHeadline>
                                    <Headline>Our company is comprised of leading scientists in the field of liver regeneration, and specialized business leaders.</Headline>
                                </CompanyHeadWrap>
                            </CompanyHeadWrapWrap>
                        </CompanyHead>
                        <Personnel>
                            <TeamSelector>
                                <TeamContainer>
                                    <TeamMenu>
                                        <SelectBlock>
                                            <TeamItem src="#" onClick={() => this.teamGo("science")} display={this.state.science_arrow_display} color={this.state.science_color}><TeamNumber>01</TeamNumber> Founding Scientists</TeamItem>
                                        </SelectBlock>
                                        <SelectBlock>
                                            <TeamItem src="#" onClick={() => this.teamGo("mgmt")} display={this.state.mgmt_arrow_display} color={this.state.mgmt_color}><TeamNumber>02</TeamNumber> Management</TeamItem>
                                        </SelectBlock>
                                    </TeamMenu>
                                </TeamContainer>
                            </TeamSelector>
                            <Management display={this.state.mgmt_display}>
                                <TeamSection>Management</TeamSection>
                                <TeamMember>
                                    <TeamPhotoHolder>
                                        <TeamImage src={ron} />
                                    </TeamPhotoHolder>
                                    <BioHolder>
                                        <BioName>Ronald G. Landes</BioName>
                                        <BioTitle>Chief Executive Officer, Co-Founder</BioTitle>
                                        <Bio>
                                            <BioP>Mr. Landes founded and led Landes Bioscience, publisher of 50 peer-reviewed life science journals, for 20 years. He is president of the nonprofit Solving Organ Shortage and currently serves on the board of directors and scientific advisory boards for several organizations, including Quantitative Insights, Oncoceutics, p53 Therapeutics and the Georgian Telemedicine Association. He has experience in technology commercialization and has invested in 20 early-stage life science companies in the US, Europe and Israel. Mr. Landes earned a BS in biochemistry from the University of Chicago, an MD from the Medical College of Wisconsin and trained in surgery at the University of Minnesota.</BioP>
                                        </Bio>
                                    </BioHolder>
                                </TeamMember>
                                <TeamMember>
                                    <TeamPhotoHolder>
                                        <TeamImage src={christina} />
                                    </TeamPhotoHolder>
                                    <BioHolder>
                                        <BioName>Christina Galbraith</BioName>
                                        <BioTitle>Director of Business Development</BioTitle>
                                        <Bio>
                                            <BioP>Christina Galbraith is the founder of Biohebe LLC, a New York based firm that provides business development for companies treating degenerative diseases. Recent projects include placing a genetic vector that reversed glaucoma at the National Institute of Medicine in France, under formal license review with a US publicly traded pharmaceutical group. Christina was formerly head of media and content at Enhanced Education, a private foundation in New York that established the graduate Program for Evolutionary Dynamics at Harvard University.  She has written extensively about genetic vectors and regenerative medicine for national publications including Newsweek, Techonomy and Futurism. Christina has a BA and Masters degree from Oxford University in the UK and is a candidate for the Certificate of Biotechnology at Columbia University in New York.</BioP>
                                        </Bio>
                                    </BioHolder>
                                </TeamMember>
                            </Management>
                            <Scientists display={this.state.science_display}>
                                <TeamSection>Founding Scientists</TeamSection>
                                <TeamMember>
                                    <TeamPhotoHolder>
                                        <TeamImage src={ira} />
                                    </TeamPhotoHolder>
                                    <BioHolder>
                                        <BioName>Ira Fox, MD</BioName>
                                        <BioTitle>Principal Investigator</BioTitle>
                                        <Bio>
                                            <BioP>
                                            Dr. Fox's major research interests involve the study of experimental therapies for treating patients with liver disease. He has initiated clinical trials involving extracorporeal human and pig liver perfusion to treat patients with acute liver failure, and has demonstrated successful correction of liver-based metabolic deficiencies in newborns and young children by transplantation of isolated liver cells. His work has been continuously supported by grants from the NIH and the DoD for over 20 years.
                                            </BioP>
                                            <BioP>
                                            He is a graduate of Harvard College and the Columbia University College of Physicians and Surgeons. Dr. Fox did a research fellowship in Immunology with Baruj Benacerraf at Harvard Medical School and a surgical transplant fellowship with Dr. Thomas Starzl at the University of Pittsburgh. Prior to moving back to Pittsburgh in 2008, Dr. Fox was senior associate dean for research and MD-PhD program director at the University of Nebraska Medical Center.
                                            </BioP>
                                        </Bio>
                                    </BioHolder>
                                </TeamMember>
                                <TeamMember>
                                    <TeamPhotoHolder>
                                        <TeamImage src={alex} />
                                    </TeamPhotoHolder>
                                    <BioHolder>
                                        <BioName>Alejandro (Alex) Soto-Gutiérrez, MD, PhD</BioName>
                                        <BioTitle>Principal Investigator, Co-Founder</BioTitle>
                                        <Bio>
                                            <BioP>
                                            Dr. Soto-Gutiérrez is a tenured associate professor in the Department of Pathology at the University of Pittsburgh. He is also an affiliated faculty member of the McGowan Institute, Children's Hospital of Pittsburgh of UPMC, and the Thomas E. Starzl Transplantation Institute. Prior to joining PITT, he was a surgery research fellow with the Department of Surgery at Massachusetts General Hospital, Harvard Medical School, and the Shriners Hospitals for Children in Boston.&nbsp; He earned his MD from the University of Guadalajara, Mexico, and completed a surgical fellowship from the Department of Surgery of Okayama University Graduate School of Medicine and Dentistry, Japan, while writing his PhD dissertation on liver tissue engineering.&nbsp;
                                            </BioP>
                                            <BioP>
                                            Dr. Soto-Gutiérrez has been the recipient of numerous awards, including the 2008 Clinical Research Award for a Post-Graduate Fellow in Transplantation from the New England Organ Bank, the 2009 Thomas E. Starzl, MD Postdoctoral Research Award from the American Liver Foundation, the 2012 Faculty Development Award from the American Society of Transplantation, and the 2013 Competitive Medical Research Fund Program Award from UPMC Health System. He also received a National Institutes of Health Pathway to Independence Award (K99/R00). He is the Editor-in-Chief of the scientific journal, Organogenesis, and Chief Science Coordinator for the Liver Community at Solving Organ Shortage, and chair of the Regenerative Transplant Community of Practice at the American Society of Transplantation. He is author of more than 82 peer-reviewed publications, nine book chapters, and is editor of a book on methods of cell transplantation.&nbsp;
                                            </BioP>
                                            <BioP>
                                            Dr. Soto-Gutiérrez’s clinical interest is in using regenerative and disruptive approaches in the treatment of liver diseases with the goal of alleviating the critical shortage of livers for transplantation. His research interest is focused on the development of new technologies for organ replacement using regenerative medicine approaches (auxiliary partial liver transplantation, cell transplantation, cell and organ engineering, genetic reprogramming) to generate entire replacement livers. His laboratory is actively working in liver cell differentiation and understanding hepatic maturation of human induced pluripotent stem (iPS) cells using interactions with liver non-parenchymal liver cells, a 3D liver matrix, and different molecules. While portions of these investigations are carried out in organ culture systems, several ongoing studies are being performed in specially engineered animal models of liver regeneration to better understand the repopulation capacities of hepatic tissue or liver grafts derived from human iPS cells. In addition, Dr. Soto-Gutiérrez’s laboratory is interested in strategies for reprogramming diseased livers and ways to induce super functions in livers (e.g. acute or chronic liver failure, liver steatosis, liver preservation).
                                            </BioP>
                                        </Bio>
                                    </BioHolder>
                                </TeamMember>
                            </Scientists>
                        </Personnel>
                        <Footer />
                    </Wrapper>
                </Boom>
            );
        } else {
            return (
                <div>&nbsp;</div>
            );
        }
    }
}

export {Company}
