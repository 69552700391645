import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Home } from './components/App';
import { Publications } from './components/Publications';
import { Contact } from './components/Contact';
import { Company } from './components/Company';
import { Tech } from './components/Tech';
import { IP } from './components/IP';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Router>
        <div>
            {/*Visitor routes*/}
            <Route exact path="/" component={Home} />
            <Route path="/publications" component={Publications} />
            <Route path="/contact" component={Contact} />
            <Route path="/company" component={Company} />
            <Route path="/technology" component={Tech} />
            <Route path="/ip" component={IP} />
        </div>
    </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
